import { CommonModule, TitleCasePipe } from '@angular/common';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatBadgeModule } from '@angular/material/badge';
import { DateAdapter, MatNativeDateModule, MatPseudoCheckboxModule, MatRippleModule } from '@angular/material/core';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { RouterModule } from '@angular/router';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { NgxSpinnerModule } from 'ngx-spinner';
import { AuthGuard } from '../guards/auth.guard';
import { CustomDateAdapter } from '../services/custom-data-adapter.service';
import { TokenInterceptor } from '../services/token.interceptor.service';
import { MatSelectFilterComponent } from './components/mat-select-filter/mat-select-filter.component';
import { NavLateralComponent } from './components/nav-lateral/nav-lateral.component';
import { NavbarComponent } from './components/navbar/navbar.component';
import { CambiarRolComponent } from './components/cambiar-rol/cambiar-rol.component';

import { EdadCasPipe } from './pipes/edad-cas.pipe';
import { EtiquetaExportPipe } from './pipes/etiqueta-export.pipe';
import { EtiquetaPipe } from './pipes/etiqueta.pipe';
import { FechasTransformPïpe } from './pipes/fechasTransform.pipe';
import { FormatDatePipe } from './pipes/format-date.pipe';
import { FormatoNombrePipe } from './pipes/formato-nombre.pipe';
import { gruposMedicosPipe } from './pipes/gruposMedicos.pipe';
import { ReversePipe } from './pipes/reverse.pipe';
import { RutPipe } from './pipes/rut.pipe';
import { ValidicFormatPipe } from './pipes/validic-format.pipe';
import { AngularEditorModule } from './components/editor/angular-editor.module';
import { E401Component } from './components/errors/401/E401.components';
import { CambiarClaveComponent } from "./components/cambiar-clave/cambiar-clave.component";
import { ChatV2Component } from "./components/chat/v2/chat.component";
import { CropperPhotoComponentForm } from "./components/cropper-photo-form/cropper-photo-form.component";

import {
  MatDialogContent,
} from 'src/app/shared/directives/dialog-content.directive';

import {
  MatDialogClose,
} from 'src/app/shared/directives/dialog-close.directive';

import {
  MatDialogActions,
} from 'src/app/shared/directives/dialog-actions.directive';

import {
  MatDialogTitle,
} from 'src/app/shared/directives/dialog-title.directive';

import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { LanguageSelectorComponent } from 'src/app/shared/components/language-selector/language-selector.component';
import { PassMatchDirective } from 'src/app/shared/directives/pass-match.directive';
import { TranslateValidatorMessageLoader } from 'src/app/shared/services/translate-validator-message-loader';

import { AzInputControlModule } from 'src/app/shared/az-input-control';
import { AzLoadersModule } from "src/app/shared/az-loaders";
import { CasMultiSelectEtiquetaComponent } from './components/cas-multiselect-etiqueta/cas-multiselect-etiqueta.component';
import { ProfileComponent } from './components/profile/profile.component';
import { CropperPhotoComponent } from './components/profile/components/cropper-photo/cropper-photo.component';

import { ImageCropperModule } from 'ngx-image-cropper';

import { NgxMaskModule } from 'ngx-mask';
import { AzAdherenciaDiaComponent } from './components/adherencia-dia/adherencia-dia.component';
import { AzAdherenciaGeneralComponent } from './components/adherencia-general/adherencia-general.component';
import { AzMetricasComponent } from './components/metricas/metricas.component';
import { AzMetasBeneficiariosComponent } from './components/metas-beneficiarios/metas-beneficiarios.component';
import { AzTableModule } from './az-table';
import { GlicosiladaComponent } from './components/glicosilada/glicosilada.component';
import { ContainerComponent } from './components/glicosilada/components/container/container.component';
import { crearGlicoComponent } from './components/glicosilada/components/container/form/crear-glicosilada.component';
import { MatButtonModule } from '@angular/material/button';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatChipsModule } from '@angular/material/chips';
import { MatDialogModule } from '@angular/material/dialog';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatMenuModule } from '@angular/material/menu';
import { MatProgressBarModule } from '@angular/material/progress-bar';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { MatSelectModule } from '@angular/material/select';
import { MatSlideToggleModule } from '@angular/material/slide-toggle';
import { MatSnackBarModule } from '@angular/material/snack-bar';
import { MatTabsModule } from '@angular/material/tabs';
import { MatTooltipModule } from '@angular/material/tooltip';

export function createValidationLoader(translate: TranslateService) {
  return new TranslateValidatorMessageLoader(translate);
}

@NgModule({
  declarations: [
    LanguageSelectorComponent,
    NavbarComponent,
    CambiarRolComponent,
    NavLateralComponent,
    EtiquetaPipe,
    gruposMedicosPipe,
    FormatDatePipe,
    EdadCasPipe,
    ReversePipe,
    FechasTransformPïpe,
    ValidicFormatPipe,
    EtiquetaExportPipe,
    FormatoNombrePipe,
    RutPipe,
    MatSelectFilterComponent,
    E401Component,
    CambiarClaveComponent,
    ChatV2Component,
    MatDialogContent,
    MatDialogClose,
    MatDialogActions,
    MatDialogTitle,
    PassMatchDirective,
    CasMultiSelectEtiquetaComponent,
    ProfileComponent,
    CropperPhotoComponent,
    AzAdherenciaDiaComponent,
    AzAdherenciaGeneralComponent,
    AzMetricasComponent,
    AzMetasBeneficiariosComponent,
    GlicosiladaComponent,
    ContainerComponent,
    crearGlicoComponent,
    CropperPhotoComponentForm
  ],
  imports: [
    TranslateModule,
    AngularEditorModule,
    CommonModule,
    FormsModule,
    HttpClientModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatPseudoCheckboxModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    ReactiveFormsModule,
    RouterModule,
    NgxSpinnerModule,
    AzInputControlModule,
    AzLoadersModule,
    MatTooltipModule,
    ImageCropperModule,
    NgxMaskModule.forRoot(),
    AzTableModule.forRoot({
      paginable: true
    })
  ],
  exports: [
    TranslateModule,
    LanguageSelectorComponent,
    NavbarComponent,
    CambiarRolComponent,
    NavLateralComponent,
    AngularEditorModule,
    CommonModule,
    EdadCasPipe,
    EtiquetaExportPipe,
    EtiquetaPipe,
    gruposMedicosPipe,
    FormatoNombrePipe,
    RutPipe,
    FormsModule,
    HttpClientModule,
    MatBadgeModule,
    MatButtonModule,
    MatCardModule,
    MatCheckboxModule,
    MatChipsModule,
    MatDatepickerModule,
    MatDialogModule,
    MatExpansionModule,
    MatFormFieldModule,
    MatIconModule,
    MatInputModule,
    MatMenuModule,
    MatNativeDateModule,
    MatProgressBarModule,
    MatProgressSpinnerModule,
    MatPseudoCheckboxModule,
    MatRippleModule,
    MatSelectModule,
    MatSlideToggleModule,
    MatSnackBarModule,
    MatTabsModule,
    NgxMatSelectSearchModule,
    // NgxSpinnerModule,
    ReactiveFormsModule,
    FormatDatePipe,
    FechasTransformPïpe,
    ValidicFormatPipe,
    MatSelectFilterComponent,
    ChatV2Component,
    MatDialogContent,
    MatDialogClose,
    MatDialogActions,
    MatDialogTitle,
    PassMatchDirective,
    AzInputControlModule,
    AzLoadersModule,
    MatTooltipModule,
    CasMultiSelectEtiquetaComponent,
    NgxMaskModule,
    AzAdherenciaDiaComponent,
    AzAdherenciaGeneralComponent,
    AzMetricasComponent,
    AzMetasBeneficiariosComponent,
    AzTableModule,
    GlicosiladaComponent,
    ContainerComponent,
    crearGlicoComponent,
    CropperPhotoComponentForm
  ],
  providers: [
    AuthGuard,
    TitleCasePipe,
    { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
    { provide: DateAdapter, useClass: CustomDateAdapter },
  ]
})
export class SharedModule { }